// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import ImageAndWords from 'components/Contentful/ImageAndWords'
import HowTo from 'components/Contentful/HowTo'
import Brands from 'components/Contentful/Brands'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Heizung sanieren leicht gemacht"
        meta={[
          { name: 'description', content: 'Wir kümmern uns fachgerecht um die Sanierung Ihrer alten Heizung ✔️ einfach ✔️ schnell ✔️ kompetent. Gleich Heizungscheck durchführen!' },
          { name: 'keywords', content: 'Heizung erstzen, Heizungsersatz' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='772bBWIn5pGfy5v3xebJOE-6FwHQZaAUqJ34e3K2ovLpi'
          id='aus-alt-mach-neu'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "6FwHQZaAUqJ34e3K2ovLpi",
    "updatedAt": "2023-07-25T12:43:47.692Z",
    "title": "Heizungsersatz mit Wärmepumpe einfach gemacht",
    "size": "Groß",
    "teaser": "Ein Heizungsersatz erfordert eine sorgfältige Vorbereitung. Unsere Heizungs-Spezialisten stehen Ihnen beratend zur Seite und übernehmen die gesamte Planung und Koordination. Dank guter Konzeption profitieren Sie von vielen weiteren Vorteilen einer Wärmepumpe.",
    "image": {
        "contentType": "image/jpeg",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/4WDlklDFyWskv52tIG7SPb/594ad997653052824e2aa29f9a825bc4/Stiebel_Eltron_W__rmepumpe_WPL_Hero.jpg"
    },
    "links": [
        {
            "type": "link",
            "id": "7p7eaG5GRI8A1fElOkNxIb",
            "updatedAt": "2022-06-28T08:23:33.929Z",
            "label": "Online Heizungs-Check",
            "url": "https://www.heizungsmacher.ch/konfigurator"
        }
    ],
    "badge": {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    "anchorName": "aus-alt-mach-neu",
    "backgroundColor": "weiß"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='772bBWIn5pGfy5v3xebJOE-7ELSfr1waa5cnnNPHd1wVy'
          id='einer-fuer-alle'
        >
          <ImageAndWords
            {...{
    "type": "imageAndWords",
    "id": "7ELSfr1waa5cnnNPHd1wVy",
    "updatedAt": "2021-05-19T07:29:01.802Z",
    "title": "Aus einer Hand",
    "text": "<p><strong>Vieles spricht für eine Wärmepumpe.</strong> Dank guter Konzeption und bester Planung unserer Wärmepumpen-Spezialisten ist auch der Umbau einer bestehenden Heizung für den Bauherrn nicht mehr so kompliziert wie es einmal war.</p>\n<p><strong>Alle Arbeiten und Einsätze werden aus einer Hand organisiert.</strong> Elektriker, Baumeister, Heizungsinstallateure, Gärtner wie auch <strong>Baugesuche</strong> und <strong><a href=\"https://www.heizungsmacher.ch/foerdergelder/\">Fördergelder</a>-Bewilligungen</strong> werden durch unser Team organisiert.</p>\n<p>Von der ersten Beratung bis hin zur Abnahme Ihrer Wärmepumpe, der zukünftigen Service-Einsätze und der Online-Überwachung — <strong>wir sind Ihr einziger Ansprechpartner.</strong></p>\n",
    "image": {
        "description": "Geschäftsmodell Heizungsmacher",
        "contentType": "image/svg+xml",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/30q540qZmsbmx274nwzdea/3421f9d00f4155ab427b19926b7f6e78/Icon_Gesch__ftsmodell_Vector_copy-01.svg"
    },
    "position": true,
    "anchorName": "einer-fuer-alle"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--white'
          key='772bBWIn5pGfy5v3xebJOE-2l7WFyX79TRYbX4l3P6JhV'
          id=''
        >
          <HowTo
            {...{
    "type": "howTo",
    "id": "2l7WFyX79TRYbX4l3P6JhV",
    "updatedAt": "2020-12-18T16:51:04.762Z",
    "title": "So läuft ein Heizungsersatz ab",
    "steps": [
        {
            "type": "howToStep",
            "id": "3Sb7UtEp4oDOlsawW4mMSX",
            "updatedAt": "2020-12-29T14:41:05.427Z",
            "title": "Markenunabhängige Beratung",
            "description": "<p>Wärme begleitet uns ein Leben lang. Nehmen Sie sich die Zeit für eine <strong>100% markenunabhängige Beratung</strong>. So können wir zusammen mit dem lokalen Profi genau die richtige Wärmepumpe für Ihr Eigenheim aussuchen. Machen Sie Ihr zukunftsfähiges Heizsystem langfristig nachhaltig mit unserer aktiven Begleitung. Wir beraten Sie sowohl telefonisch als auch direkt bei Ihnen vor Ort.</p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/MeLkK8almJvfpHd3Ig1NK/38d73643d7f6f8a2841bc693a420ffba/Icon_1_Beratung_copy-01.svg"
            },
            "backgroundColor": "weiß"
        },
        {
            "type": "howToStep",
            "id": "4XNQYJ08t919iysB15UP40",
            "updatedAt": "2021-04-07T12:40:04.467Z",
            "title": "kostenlose Offerte",
            "description": "<p>Nur die richtige Auswahl und Dimensionierung der Wärmepumpe bringt die nötige Effizienz. Bei uns dürfen Sie sich über eine <strong>kostenlose Offerte</strong> freuen, in der Sie alle Arbeiten und Materialien bis ins kleinste Detail erkennen. Damit bewahren Sie jederzeit den Überblick. Je nach Ihrem Wunsch unterbreiten wir Ihnen entweder eine Richtofferte oder ein definitives Angebot.</p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3baxjzodsdku2wP4GsT37q/9a6321d699adbdc8514390a256060788/Icon_2_Offerte_copy-01.svg"
            },
            "backgroundColor": "grau"
        },
        {
            "type": "howToStep",
            "id": "3mAupbxEoQbUSqZw1p2mEF",
            "updatedAt": "2020-12-29T14:42:00.862Z",
            "title": "Bestellung",
            "description": "<p>Sie können Ihre Offerte <strong>online oder per Post</strong> zurück bestätigen und schon haben Sie bestellt. Wir machen uns umgehend an die Arbeit und melden uns schon bald mit einem effizienten Projektplan bei Ihnen.</p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2uR1Cz8AInHtXXNLEisyI2/93226c108804ed2f4a551a286bf4b548/Icon_3_Bestellung_copy-01.svg"
            },
            "backgroundColor": "weiß"
        },
        {
            "type": "howToStep",
            "id": "3Dvbko8W6WKjDxeVOzV03E",
            "updatedAt": "2021-05-19T07:29:29.378Z",
            "title": "Bewilligung und Fördergelder",
            "description": "<p>Die Zeiten von einfachem Bauen sind vorbei. Administrative Prozesse, Bewilligungsauflagen und Anmeldungen für <a href=\"https://www.heizungsmacher.ch/foerdergelder/\">Fördergelder</a> müssen zeitlich und inhaltlich eingehalten werden. Als Wärmepumpen-Spezialisten haben wir langjährige Erfahrung mit sämtlichen Kontaktstellen. Sie dürfen diese <strong>Aufgaben ganz uns überlassen</strong> und den reibungslosen Heizungsumbau geniessen.</p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3d5sUn1XvBw4BXXBcPRIba/86254cc9dc67e2a0b3e2cbddf9e48b48/Icon_4_Bewilligung_copy-01.svg"
            },
            "backgroundColor": "grau"
        },
        {
            "type": "howToStep",
            "id": "2l9Pw591zMU6tDeiSFCsZ8",
            "updatedAt": "2020-12-29T14:42:51.124Z",
            "title": "Installation",
            "description": "<p>Zusammen mit unseren <strong>lokalen und etablierten Handwerkern</strong> wird die neue Wärmepumpen Heizung installiert. Wir kontrollieren die korrekte Ausführung, bringen Beschriftungen an, erklären Ihnen das System und verbinden die Anlage für das weitere Überwachen mit dem Internet.</p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7kOFVkLTPifJMSesUpTvZM/2fe18f7df197cecf62266f868f42fb69/Icon_5_Installation_copy-01.svg"
            },
            "backgroundColor": "weiß"
        },
        {
            "type": "howToStep",
            "id": "1hXi9dekVkgEF6wXValAXM",
            "updatedAt": "2021-05-19T07:30:12.041Z",
            "title": "Betrieb & aktives Online-Monitoring",
            "description": "<p>Um Komfort und Effizienz in Ihr Eigenheim zu holen, muss die Wärmepumpe zuerst richtig eingestellt werden. Über unser <strong><a href=\"https://www.heizungsmacher.ch/monitoring/\">Online-Monitoring</a> überwachen</strong> wir Ihre Wärmepumpe stetig. Serviceeinsätze können somit frühzeitig organisiert werden. Zudem können jederzeit notwendige System-Einstellungen in Absprache mit Ihnen ferngesteuert vorgenommen werden.</p>\n",
            "image": {
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1xuCcFsF06kJrGFi72X0Aw/83f15badb458c0019fe733f55d1dcd91/Icon_6_Betrieb_copy-01.svg"
            },
            "backgroundColor": "grau"
        }
    ],
    "backgroundColor": "weiß"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='772bBWIn5pGfy5v3xebJOE-7rNjuLBYSQ8Sgseuy4i2GA'
          id='verbaende'
        >
          <Brands
            {...{
    "type": "brands",
    "id": "7rNjuLBYSQ8Sgseuy4i2GA",
    "updatedAt": "2021-02-02T10:31:03.938Z",
    "title": "Verbände",
    "description": "<p>Wir engagieren uns aktiv bei den führenden Schweizer Verbänden rund um das Thema Wärmepumpen.</p>\n",
    "logos": [
        {
            "description": "Logo farbig WPSM Wärmepumpen Systemmodul",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1hFANjuUwoMmY6k8koyIY2/b017b7a1398e31e2440d8bfdae9eb154/WPSM_Logo_-_Heizungsmacher_AG.svg"
        },
        {
            "description": "Logo farbig FWS Fachvereinigung Wärmepumpen Schweiz",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/6sBKzwYHpCa4KAkKIW2EE2/671d07ad83b5b3e110f43631f9fe8ba6/FWS_Logo_-_Heizungsmacher_AG-01.svg"
        },
        {
            "description": "Logo farbig Öbu - Der Verband für nachhaltige Wirtschaften",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/3JdPIe5dDVR2H6rk1cmOPV/543a601ea010222e82242b35247e2cc2/__bu_Logo_-_Heizungsmacher_AG-01.svg"
        },
        {
            "description": "Logo farbig Swissolar - der schweizerische Verband für Sonnenenergie",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1DB0UMvLz8g9ljOyusqfLR/f30a65127880b6dfdc8684ca8ae8e34e/Swisssolar_Logo_-_Heizungsmacher_AG.svg"
        },
        {
            "description": "Logo farbig Wir, die Gebäudetechniker",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/5jOjrinc1tgJLgaqtp9WHx/0dd8e8ea14a94a3cf215b47e0b879bff/Geb__udetechniker_Logo.svg"
        },
        {
            "description": "Logo farbig Suissetec - Der Gebäudetechnikverband",
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/3MsyP7GgMyadkzMexGFG8g/fadc55692ba6dbb14c9f60dca258f391/Suissetec_Logo_-_Heizungsmacher_AG.svg"
        },
        {
            "contentType": "image/svg+xml",
            "url": "//images.ctfassets.net/wbm1kpreb3m8/1FD8ynKTqE8GFy5RYKC46i/29e50740fd742fbac74601cd130ea1ef/Solarprofil_Logo_farbig-01.svg"
        }
    ],
    "anchorName": "verbaende",
    "backgroundColor": "grau"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
